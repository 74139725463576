<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Elements component
 */
export default {
  page: {
    title: "User Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "User Setting",
      items: [
        {
          text: "User",
          href: "/user/list",
        },
        {
          text: "setting",
          active: true,
        },
      ],
      user:{
        fullname: '',
        contact1: '',
        contact2: '',
        email: '',
        remark: ''
      },
      permission_list:[],
      arr_pms: [],

      granted_user_list: [],
    };
  },
  computed:{
    compute_user(){
      return this.$store.state.authfack.user
    }
  },
  created(){
    this.load_permission_list()
    this.load_granted_list()
    this.load_detail()
  },
  methods:{
    load_detail(){
      let self = this
   
      const baseURI = 'users/'+self.compute_user.id
      self.$http.get(baseURI)
      .then((result) => {
        if(result.data.status == 'success'){
          // console.log(result.data.data, !result.data.data.permissions.includes('*'))
          if(!result.data.data.permissions.includes('*')){
            self.arr_pms = result.data.data.permissions.split(',').map( Number )
          }
        }
      })
    },
    load_permission_list(){
      let self = this
      self.permission_list = []
      const baseURI = 'permissions'
      self.$http.get(baseURI)
      .then((result) => {
        if(result.data.status == 'success'){
          // let title_array = []
          // console.log(result.data.data)
          result.data.data.forEach(function(item){
            if(item.object !== 'log'){
              item.new_id = item.id
              self.permission_list.push(item)

              if(self.compute_user.permissions.includes("*")){
                self.arr_pms.push(item.new_id)
              }
            }
            
          })

        }
      })
    },
    load_granted_list(){
      let self = this
      self.granted_user_list = []
      const baseURI = 'user_users/list'
      let formData = new FormData()
      formData.append('user_uuid', this.compute_user.id)
      self.$http.post(baseURI, formData)
      .then((result) => {
        if(result.data.status == 'success'){
          self.granted_user_list = result.data.data
        }
      })
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <h6>Permissions</h6>
        <div class="row">
          <div class="mt-2 col-lg-4" v-for="item in permission_list" :key="item.id">
            <b-form-checkbox
              disabled
              :value="item.new_id"
              unchecked-value="0"
              v-model="arr_pms"
              plain
              >{{ item.desc }}</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <h6 class="mt-4">Granted Access</h6>
        <table class="table mt-2" v-if="granted_user_list.length > 0">
          <thead>
            <tr>
              <th>No</th>
              <th>User Account</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in granted_user_list" :key="index">
              <td>{{index+1}}</td>
              <td>{{item.granted_user.name}}</td>
              
            </tr>
          </tbody>
        </table>
        <div v-else>No granted access for users</div>
      </div>
    </div>
  </Layout>
</template>
